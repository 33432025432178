@import '/src/shared/styles';

.cover {
  z-index: 1;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    position: absolute;
    top: 25%;
    left: $gutter-phone;
    width: 100%;
    font-size: 50px;
    line-height: 53px;
    font-weight: 200;
    text-transform: uppercase;
    z-index: 1;
    color: $green;
    opacity: 0;
    @extend %fadeIn;
    animation-delay: 0.25s;

    @include media('>phone') {
      font-size: 91px;
      line-height: 90px;
      font-weight: 100;
      left: $gutter-tablet;
      width: 60%;
    }

    @include media('>tablet') {
      font-size: 110px;
      line-height: 100px;
      left: 35vw;
      width: 55%;
    }

    @include media('>desktop') {
      font-size: 135px;
      line-height: 125px;
    }

    span {
      display: inline-block;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    @extend %fadeIn;
    animation-duration: 0.75s;
  }

  .coverPic {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
  }
}

.cookies {
  font-size: 18px;
  line-height: 18px;
  color: $black;
  width: 100%;
  transition: 1s $ease;
  margin-top: 10px;

  @include media('>tablet') {
    font-size: 20px;
    line-height: 20px;
    font-weight: 300;
    position: fixed;
    bottom: 137px;
    right: 10vw;
    width: auto;
    display: inline-flex;
    align-items: center;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  .txt {
    text-transform: none;
    display: flex;
    align-items: center;
  }

  button {
    color: $green;
    background: $white;
    border: 1px solid;
    font-weight: 400;
    padding: 8px 18px;
    margin-top: 10px;
    cursor: pointer;

    @include media('>tablet') {
      margin-top: 0;
      margin-left: 20px;
      transform: translateY(-2px);
    }
  }
}
