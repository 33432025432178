@import "/src/shared/styles";

.footer {
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  background: $green;
  padding: 0;
  opacity: 0;
  @extend %fadeIn;
  animation-delay: .5s;
  letter-spacing: 0px;

  @include media('>phone') {
    height: 127px;
  }

  @include media('>tablet') {
    left: 35vw;
    right: 10%;
    width: auto;
    justify-content: start;
    padding: 0 3%;
  }

  @include media('>desktop') {
    font-size: 91px;
  }
}

.text {
  text-align: left;
  transform: translateY(3px);
  width: calc(100% - #{2 * $gutter-phone});

  @include media('>phone') {
    transform: translateY(5px);
  }

  & > span {
    display: block;
  }
}

.title {
  color: white;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.date {
  width: 100%;
  color: white;
  opacity: 0.6;
}

.arrow {
  color: white;
  height: 46px;
  position: relative;
}

.address {
  color: white;
  display: flex !important;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    margin-right: 5px;
    transform: translateY(-3px);
  }
}

.addressText {
  //height: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
