@import "/src/media-query-mixins";

.container {
  @include media('>phone') {
    @include orientation(landscape) {
      display: flex;
    }
  }
}

.img {
  width: 100%;
  height: 0;
  margin-bottom: 40px;
  position: relative;

  .inner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }

  @include media('>tablet') {
    width: calc(50% - 15px);
    float: left;
  }
}

.title {
  font-size: 12px;
  text-transform: capitalize;
  line-height: 12px;
  margin-top: 10px;

  @include media('>desktop') {
    font-size: 12px;
    line-height: 12px;
  }
}

.btn {
  text-decoration: none;
  color: white;
  display: inline-block;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 20px;


  &:last-of-type {
    margin-bottom: 0;
  }
}

.rightCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @include media('>phone') {
    align-items: flex-start;
    @include orientation(landscape) {
      margin-left: 45px;

      p {
        margin: 0;
      }
    }
  }

  @include media('>tablet') {
    width: 35vw;
    float: right;
    height: 35vh;
  }

  @include media('>desktop') {
    width: 26vw;
    height: 30vh;
  }
}
