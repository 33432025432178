@import "/src/media-query-mixins";

.content {
  color:  #707070;
}

.list {
  font-size: 16px;
  line-height: 25px;
  box-sizing: border-box;
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;

  @include media('>phone') {
    font-size: 18px;
    line-height: 37px;
    width: 100%;
  }

  @include media('>tablet') {
  }

  @include media('>desktop') {
    font-size: 22px;
    line-height: 37px;
  }
}

.listItem {
  padding-top: 10px;
  border-top: 1px solid;
  box-sizing: border-box;
  flex-shrink: 0;
  margin-bottom: 30px;
  margin-top: 7px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include media('>phone') {
    width: 45%;
    margin-bottom: 60px;
    overflow: hidden;

    &:nth-of-type(odd) {
      margin-right: 10%;
    }
  }

  @include media('>tablet') {
    width: calc(70% / 3);
    margin-right: 15% !important;

    &:nth-of-type(3n) {
      margin-right: 0 !important;
    }
  }
}
