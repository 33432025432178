@keyframes spinHorizontal {
  from {
    transform: rotateY(0deg);
    opacity: 0;
  }
  to {
    transform: rotateY(calc(360deg * 3));
    opacity: 1;
  }
}

@keyframes slideInBottom {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-10%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(10%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes slideInRightEasy {
  from {
    transform: translateX(5%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

$ease: cubic-bezier(0.23, 1, 0.32, 1);

%spin {
  animation: spinHorizontal 2s forwards $ease;
}

%slideInBottom {
  animation: slideInBottom 1s forwards $ease;
}

%slideInLeft {
  animation: slideInLeft .75s forwards $ease;
}

%slideInRight {
  animation: slideInRight .75s forwards $ease;
}

%slideInRightEasy {
  animation: slideInRightEasy 1s forwards $ease;
}

%fadeIn {
  animation: fadeIn 1s forwards $ease;
}

%scaleIn {
  animation: scaleIn 2s forwards $ease;
}
