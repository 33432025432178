@import "/src/shared/styles";

.bgWrap {
  position: absolute;
}

.paragraph {

  @include media('>phone') {
    top: 22%;
  }

  @include media('>tablet') {
    font-size: 28px;
    line-height: 37px;
    top: 35%;
  }
}

.ourProductsArt {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.splash {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  bottom: 0;
  background: white;
  z-index: 2;
  padding: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transform: translateX(100%);
  opacity: 0;
  color: $green;
  box-sizing: border-box;
  box-shadow: -6px 0 16px rgba(0, 0, 0, 0.1);

  @include media('>phone') {
    width: 62vh;
  }

  @include media('>tablet') {
    padding: 5vh;
  }

}

.closeSplashBtn {
  width: 80%;
  font-size: 18px;
}

.art {
  @include media('>tablet') {
    flex-shrink: 1;
  }
}
