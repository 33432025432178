.readMoreSection {
  pointer-events: none;
}

.readMoreBtn {
  padding: 8px 4px 0;
  border-bottom: 1px solid;
  text-transform: uppercase;
  margin-top: 20px;
  border-radius: 0;
  display: inline-block;
}
