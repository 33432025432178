.video {
  position: absolute;

  video {
    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
