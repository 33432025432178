@import "/src/shared/styles";

.fadeIn {
  opacity: 0;
  @extend %fadeIn;
}

.spread {
  width: 100%;
  height: 100%;
}
