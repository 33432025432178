@import "/src/shared/styles";

.cover {
  z-index: 1;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    position: absolute;
    top: 20%;
    left: $gutter-phone;
    width: 66%;
    z-index: 1;
    opacity: 0;
    @extend %fadeIn;
    animation-delay: 0.75s;

    @include media('>phone') {
      left: $gutter-tablet;
    }

    @include media('>tablet') {
      left: $gutter-desktop;
      width: 66%;
    }

    @include media('>desktop') {
      left: $gutter-wide-screen;
      width: 30%;
    }
  }

  .art {
    height: 50%;
    bottom: 0;
    right: 0;
    position: absolute;
    opacity: 0;
    @extend %slideInRightEasy;
    animation-delay: 1s;

    @include media('>phone') {
      height: 60%;
      right: -4%;
    }

    @include media('>tablet') {
      height: 70%;
      right: 0;

      @include orientation('portrait') {
        height: 55%;
      }
    }

  }

  .footer {
    height: 15%;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;

    @include media('>phone') {
      height: 127px;
    }

    @include media('>tablet') {
      left: 35%;
      right: 10%;
      width: auto;
    }

    @include media('>desktop') {
      font-size: 91px;
    }
  }

  .text {
    text-align: left;
    line-height: 1.5em;
    font-size: 16px;
    transform: translateY(1px);
  }

  .title {
    color: white;
    width: 100%;
  }

  .date {
    width: 100%;
    color: white;
    opacity: 0.6;
  }

  .arrow {
    color: white;
    height: 46px;
    position: relative;
  }
}

.address {
  color: white;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    margin-right: 5px;
  }
}

.addressText {
  height: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 25px);
}

.footnote {
  font-size: 12px;
  line-height: 1.5em;
  width: 30vw;
  position: absolute;
  bottom: 151px;
  left: $gutter-phone;
  font-weight: 200;
  opacity: 0;
  @extend %fadeIn;
  animation-delay: 0.75s;

  @include media('>phone') {
    font-size: 14px;
    left: $gutter-tablet;
    bottom: 20%;
    width: 35vw;
  }

  @include media('>tablet') {
    left: $gutter-desktop;
    bottom: 25%;

    @include orientation('portrait') {
      bottom: 20%;
    }
  }

  @include media('>desktop') {
    font-size: 16px;
    font-weight: 300;
    left: $gutter-wide-screen;
    top: 50%;
    bottom: unset;
  }

  .divider {
    height: 1px;
    width: 30vw;
    background: $gray;
    margin-bottom: 1em;

    @include media('>phone') {
      width: 15vw;
    }
  }
}
