@import 'dimentions';
@import './media-query-mixins';
@import 'colors';

.inner {
  width: calc(100vw - #{2 * $gutter-phone});
  box-sizing: border-box;
  height: 61%;
  bottom: 10%;
  position: fixed;
  left: $gutter-phone;
  color: $gray;
  line-height: 1.5em;
  //justify-content: space-between;

  :global {
    @include media('>phone') {
      width: calc(100vw - #{2 * $gutter-tablet});
      left: $gutter-tablet;
      height: 67%;
    }

    @include media('>tablet') {
      width: calc(100vw - #{2 * $gutter-desktop});
      left: $gutter-desktop;
      top: 20%;

      @include orientation(landscape) {
        top: 23%;

      }
    }

    @include media('>desktop') {
      width: calc(100vw - #{2 * $gutter-wide-screen});
      left: $gutter-wide-screen;
    }
  }
}
