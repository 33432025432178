@import "/src/media-query-mixins";
@import "/src/utils";

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //align-items: center;

  @include media('>tablet') {
    flex-direction: row;
  }

  @include media('>phone') {
    @include orientation('landscape') {
      flex-direction: row;
    }
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media('>phone') {
    margin-right: 10%;
    width: 90%;
  }
}

.caption {
  margin-top: 30px;
  display: block;

  @include media('>phone') {
    width: 90%;
  }
}

.carousel {
  height: 100%;

  :global {
    .carousel-item {
      box-sizing: border-box;

      @include media('>phone') {
        &:nth-child(odd) {
          padding-right: 20px;
        }

        &:nth-child(even) {
          padding-left: 20px;
        }
      }
    }
  }
  @include media('>phone') {
    height: 80%;
    @include orientation('portrait') {
      height: 50%;
    }
  }

  @include media('>tablet') {
    height: 100%;
  }
}

.itemWrapper {
  box-sizing: border-box;
  width: 100%;

  @include media('>phone') {
    width: calc(100% / 2);
  }
}
