@import "/src/media-query-mixins";
@import "/src/colors";

.text {
  @include media('>phone') {
    margin-bottom: 8%;

    @include orientation('landscape') {
      margin-bottom: 6%;
    }
  }
}

.btn {
  background: $green;
  text-decoration: none;
  padding: 17px 29px 12px;
  border-radius: 30px;
  font-size: 11px;
  line-height: 11px;
  margin: 34px 0 0;
  display: inline-block;
  flex-shrink: 0;
}

.art {
  position: absolute;
  bottom: 0;
  width: 100%;
}
