@import "/src/shared/styles";

.paragraph {
  @include media('>tablet') {
    width: calc(50% - 6vw);
  }

  @include media('>desktop') {

  }
}

.art {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  opacity: 0;
  @extend %fadeIn;
  animation-delay: .5s;
  animation-duration: 2s;

  @include media('>phone') {
    width: 60%;

    @include orientation('landscape') {
      width: 50%;
    }
  }
}
