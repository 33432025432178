.snapScroll {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    perspective: 1200px;
}

.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    scroll-behavior: auto;
    transition: .3s ease-out;
    will-change: opacity;
}

.inner {
    width: inherit;
    height: inherit;
}
