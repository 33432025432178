@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import 'colors';

body {
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100vw;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

* {
  outline: none;
}

.root {
  font-family: 'calibre', sans-serif;
  color: #707070;
  background: #ffffff;
  font-weight: 100;
}

$placeholderColor: #9f9b9b;

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $placeholderColor;
  font-style: italic;
}

::-moz-placeholder { /* Firefox 19+ */
  color: $placeholderColor;
  font-style: italic;
}

:-ms-input-placeholder { /* IE 10+ */
  color: $placeholderColor;
  font-style: italic;
}

:-moz-placeholder { /* Firefox 18- */
  color: $placeholderColor;
  font-style: italic;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

h1 {
  margin: 0;
}

.ripple {
  cursor: pointer;
}

:global {
  .waves-effect.waves-white .waves-ripple {
    $c: #ffffff;
    background: rgba($c, 0.4);
    background: -webkit-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: -o-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: -moz-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);

  }

  .waves-effect.waves-blue .waves-ripple {
    $c: #0272BA;
    background: rgba($c, 0.4);
    background: -webkit-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: -o-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: -moz-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);

  }

  .waves-effect.waves-purple .waves-ripple {
    $c: #662d91;
    background: rgba($c, 0.4);
    background: -webkit-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: -o-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: -moz-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);

  }

  .waves-effect.waves-gray .waves-ripple {
    $c: #aeaeae;
    background: rgba($c, 0.4);
    background: -webkit-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: -o-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: -moz-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);

  }

  .waves-effect.waves-red .waves-ripple {
    $c: #ed1c24;
    background: rgba($c, 0.4);
    background: -webkit-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: -o-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: -moz-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);

  }

  .waves-effect.waves-lagoon .waves-ripple {
    $c: #22B0AF;
    background: rgba($c, 0.4);
    background: -webkit-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: -o-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: -moz-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);

  }

  .waves-effect.waves-green .waves-ripple {
    $c: #005728;
    background: rgba($c, 0.4);
    background: -webkit-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: -o-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: -moz-radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);
    background: radial-gradient(rgba($c, 0.2) 0, rgba($c, 0.3) 40%, rgba($c, 0.4) 50%, rgba($c, 0.5) 60%, rgba($c, 0) 70%);

  }
}
