@import "/src/shared/styles";

.container {
}

.bizCard {
  width: 100vw;
  height: 100%;
  position: fixed;

  .mobileIcon {
    position: fixed;
    bottom: 20%;
    right: 17%;
    transform-origin: 100% 50%;
  }

  .contentBox {
    width: 266px;
    position: absolute;
    top: 20%;
    height: 60%;
    max-height: 200px;
    left: 13%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 100;
    height: 66px;
    width: 100%;
  }

  .cardLogo {
    opacity: 0;
    width: 60px;
  }

  .cardLogoTxt {
    opacity: 0;
    width: 60px;
  }
}

.logo {
  position: fixed;
  width: 142px;
  left: 17%;
  top: 10%;
  z-index: 1;
  transform: translate(-32px, -50%);
  padding: 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  opacity: 0;
  @extend %fadeIn;
  animation-delay: 1.5s;

  .logoImg {
    width: 52px;
  }
}

.logoText {
  margin-left: 15px;
  opacity: 0;
  @extend %fadeIn;
  //animation-delay: 1s;
}

.logo, .logoText {
  @include media('>phone') {
    left: 10%;

    @include orientation('landscape') {
      left: calc(#{$gutter-desktop / 2});
    }
  }

  @include media('>tablet') {
    @include orientation(landscape) {
      left: 5%;
    }
  }

  @include media('>desktop') {
    left: 8%;
  }
}

.menuToggle {
  position: fixed;
  right: 17%;
  transform: translate(50%, -50%);
  top: 10%;
  z-index: 2;
  width: 35px;
  height: 35px;
  transition: .5s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  @extend %fadeIn;
  //animation-delay: 1s;
  opacity: 0;

  @include media('>phone') {
    right: 10%;

    @include orientation('landscape') {
      right: calc(#{$gutter-desktop / 2});
    }
  }

  @include media('>tablet') {
    @include orientation(landscape) {
      right: 5%;
    }
  }

  @include media('>desktop') {
    right: 8%;
  }

  .handle {
    width: 35px;
    height: 2px;
    display: inline-block;
    transform-origin: 7.5px;
    margin: 6px;
    position: relative;
  }
}

.menuContainer {
  position: fixed;
  width: 100vw;
  height: 100%;
  background: #005728;
  color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(100vw);
  transition: 1s $ease;

  &.open {
    transform: translateX(0);
  }

  :global {
    ::-webkit-scrollbar-thumb {
      background: rgba(white, 0.3);
    }
  }

  @include media('>phone') {
    justify-content: center;
  }

  .inner {
    width: calc(100vw - 33vw);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 83%;
    padding: 0 0 40px 0;
    margin-top: 30%;
    overflow-y: auto;

    @include media('>phone') {
      width: 45vw;
      margin-top: 0;
      padding: 0;
      overflow: visible;
      transform: translateX(50px);
      justify-content: center;
    }

    @include media('>tablet') {
      width: 30vw;
      transform: translateX(50px);
    }

    & > * {
      width: 100%;
      flex-shrink: 0;
      box-sizing: border-box;
    }

    .policy {
      font-size: 18px;
      line-height: 0.5em;
      font-weight: 300;
      margin-top: 30px;
      text-decoration: underline;
      padding: 18px 20px 14px;
      border: none;
    }
  }

  .divider {
    height: 1px;
    background: rgba(white, 0.3);
    width: calc(100% - 40px);
    margin: 18px 0;

    @include media('>phone') {
      background: #68A11E;
    }
  }

  .category {
    padding: 18px 20px 14px;
    margin-top: -15px;
    margin-bottom: -10px;
    color: rgba(white, 0.5);

    @include media('>phone') {
      padding: 0;
      line-height: 1.5em;
      left: -72%;
      display: inline-block;
      width: auto;
      position: relative;
      top: 1.9em;
    }

    @include media('>tablet') {
      font-size: 22px;
      top: 1.8em;
      font-weight: 200;
    }
  }

  h1 {
    font-size: 27px;
    line-height: 0.8em;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0;
    padding-top: 22px;

    @include media('>tablet') {
      font-weight: 200;
    }
  }
}

.legal {
  text-decoration: underline;
  position: fixed;
  right: 17%;
  bottom: 4%;
  padding: 10px 30px 7px;
  transform: translate(45px, 0px);
  font-size: 20px;
  line-height: 0;
  font-weight: 200;
  border-radius: 30px;
  z-index: 1;

  @include media('>phone') {
    right: 10%;
  }

  @include media('>tablet') {
    right: 5%;
  }

  @include media('>desktop') {
    right: 8%;
    font-size: 22px;
  }
}

.frameIndicator {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  opacity: 0;
  @extend %fadeIn;
  animation-delay: 1s;

  @include media('>phone') {
    transform: translate(-50%, -50%);
    top: 50%;
    left: #{$gutter-tablet / 2};
    justify-content: center;
    height: auto;
    padding: 6px;
  }

  @include media('>tablet') {
    left: calc(#{$gutter-desktop / 2});
  }

  @include media('>desktop') {
    left: calc(#{$gutter-wide-screen / 2});
  }

  li {
    width: 4px;
    border: 1px solid;
    box-sizing: border-box;
    height: 100%;
    opacity: 0;

    @include media('>phone') {
      width: 8px;
      height: 8px;
      border-radius: 10px;
      opacity: 1;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

  .indicate {
    width: 100%;
    height: 8px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    will-change: top;
    @extend %fadeIn;
    transition: 1s $ease;

    @include media('>phone') {
      width: 8px;
      height: 8px;
      left: 6px;
      transform: translate(0, 6px);
      border-radius: 10px;
    }
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: rgba(white, 0.8);
  z-index: 1;
  height: 53px;
  padding: 5px 7.5vw 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 0;
  font-weight: 200;
  opacity: 0;
  transform: translateY(100%);
  transition: 1s $ease;

  &.show {
    transform: translateY(0%);
    opacity: 1;
  }

  @include media('>phone') {
    padding: 5px 20vw 0;
    font-size: 16px;
  }

  @include media('>tablet') {
    padding: 5px 13vw 0;
    font-size: 18px;
  }

  @include media('>desktop') {
    height: 70px;
  }

  .right {
    display: flex;
    align-items: center;

    img {
      height: 18px;
      transform: translateY(-0.2em);
      margin-left: 3px;

      @include media('>tablet') {
        height: 24px;
      }
    }
  }
}
