@import "/src/shared/styles";

.carousel {
  display: flex;
  align-items: center;
  //justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  &.bottom {
    flex-direction: column;
  }
}

.inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.btn {
  width: 30px;
  flex-shrink: 0;
  z-index: 1;
  opacity: 0;
  animation-duration: 1s;
  animation-delay: 1.1s;
  position: absolute;

  svg {
    width: 90px;
  }
}

.right {
  right: 0;
  @extend %slideInLeft;
  svg {
    transform: translate(-33px, -4px);
  }
}

.left {
  left: 0;
  @extend %slideInRight;
  svg {
    transform: translate(-28px, -4px);
  }
}

.itemGroup {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  justify-content: start;

  & > div {
    height: 100%;
  }
}

.content {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  transition: 1s $ease;

  @include media('>phone') {
    margin-bottom: 10px;
  }

  @include media('>tablet') {
    margin-bottom: 15px;
  }

  @include media('>desktop') {
    margin-bottom: 50px;
  }

  & > * {
    flex-shrink: 0;
  }

  @for $i from 1 to 10 {
    .itemWrapper:nth-child(#{$i}) {
      opacity: 0;
      @extend %fadeIn;
      animation-duration: 2s;
      animation-delay: $i * 0.2s;
    }
  }

  &.content-bottom {
    width: 100%;
  }
}

.navWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  width: calc(100% + 32px);
  box-sizing: border-box;
  position: relative;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    box-sizing: border-box;
    height: 100%;
    padding: 0 16px;
    font-size: 16px;
    border-radius: 2px;

    svg {
      width: 50px;
      flex-shrink: 0;
    }

    &.right {
      svg {
        transform: translate(0, -1px);
      }
    }

    &.left {
      svg {
        transform: translate(0, -1px);
      }
    }
  }

  .clipIcon {
    height: 35px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btnTxt {
    transform: translateY(2px);
    text-transform: uppercase;
    font-size: 20px;
    line-height: 26px;
    font-weight: 300;
  }
}

.itemWrapper {
  box-sizing: border-box;
}
