@import "/src/shared/styles";

.twoColumnLayout {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.articleForReader {
  margin-bottom: 90px;
}

.article {
  @include media('>tablet') {
    column-count: 2;
    column-gap: 4vw;
    margin-bottom: 0;
    column-fill: auto;
    height: 80%;
  }

  @include orientation('portrait') {
    column-count: 1;
  }
}

.footNotes {
  margin: 56px 0 0 0;
  padding: 17px 0 0 0;
  width: 100%;
  counter-reset: item;
  border-top: 1px solid #5E5E5E;
  font-size: 12px;
  line-height: 20px;

  @include media('>phone') {
    position: absolute;
    bottom: 0
  }

  li {
    display: block;

    &:before {
      content: "(" counter(item) ") ";
      counter-increment: item;
      width: 1.4em;
      display: inline-block;
    }
  }
}
