@import '/src/shared/styles';

.clinical {
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  height: 62%;
  bottom: 10%;

  @include media('>phone') {
    height: 64%;
    bottom: 15%;
  }
}

.clinicalCarousel {
  //height: 70%;
  //transform: translateY(-15%);
  //width: calc(78vw + 30px);

  @include media('>phone') {
    height: 65%;
    width: 100%;
    transform: translateY(-10%);
  }
}

.outerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  @include media('>phone') {
    width: 50%;
  }

  @include media('>tablet') {
    width: calc(100% / 3);
  }
}

.innerWrapper {
  border-radius: 2px;
  font-size: 16px;
  height: 100%;
  padding: 15px 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  letter-spacing: unset;

  @include media('>phone') {
    margin-right: 20%;
    width: 80%;
  }
}

.date {
  color: #8F8F8F;
  margin-bottom: 10px;
  line-height: 1em;
  display: inline-flex;
  align-items: center;
  height: 25px;

  svg {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    margin-right: 10px;
    transform: translateY(-1px);
  }

  .txt {
    transform: translateY(2px);
    position: relative;
  }
}

.header {
  line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;


  @include media('>phone') {
    line-clamp: 4;
  }
}

.source {
  //height: 40%;
  //overflow: hidden;

  //@include media('>phone') {
  //  height: 20%;
  //}
}

.menuTrigger {
  margin: 5px 0 10px 0;
  flex-shrink: 0;
  align-self: start;
}

.divider {
  width: 100%;
  height: 1px;
  background: $gray;
  margin: 15px 0 18px;
}

.empty {
  @extend %fadeIn;
  font-size: 22px;
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 200;

  @include media('>phone') {
    margin-top: 0;
  }
}
