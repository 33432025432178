@import '/src/shared/styles';

.inner {
  p {
    margin-bottom: 15%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.itemInner {
  padding-right: 3vw;
  p {
    margin-bottom: 2%;
  }

  .country {
    color: #68A11E;
  }

  .products {
    color: #004B46;
  }
}

.link {
  color: $green;

  &:visited {
    color: $green;
  }
}

.btns {
  display: flex;
  align-items: center;
  margin-top: 7%;

  a {
    padding: 7px;
    color: $green;
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  svg {
    height: 24px;
    width: 24px;
  }
}

.onePager {
  display: flex;
  flex-direction: column;
  height: calc(100% - 170px);

  @include media('>phone') {
    height: 68%;
  }

  h1, h2 {
    color: $green;
    margin-bottom: 20px;
    font-size: 25px;
    line-height: 1em;
    font-weight: 200;

    @include media('>phone') {
      font-size: 80px;
      margin-bottom: 50px;
      font-weight: 100;
    }
  }

}

.spacer {
  width: 10px;
  display: inline-block;
}

.pipe {
  display: inline-block;
  margin: 0 5px;
}

.spaceBottom {
  margin-bottom: 4%;

  @include orientation('portrait') {
    margin-bottom: 12%;
  }
}

.carouselWrapper {
  //height: 100%;
}

.carousel {
  height: 100%;
  position: relative;

  @include media('>phone') {
    height: 300px;
  }

  @include media('>tablet') {
    top: 50%;
    transform: translateY(-50%);
  }

  h2 {
    position: fixed;
    top: 21%;
  }

  @include media('>desktop') {
    height: 350px;
  }
}

/* CAROUSEL */

.clinical {
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 62%;
  bottom: 10%;

  @include media('>phone') {
    height: 64%;
    bottom: 15%;
  }
}

.clinicalCarousel {
  //height: 70%;
  //transform: translateY(-15%);
  //width: calc(78vw + 30px);

  @include media('>phone') {
    height: 65%;
    width: 100%;
    transform: translateY(-10%);
  }
}

.outerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  @include media('>phone') {
    width: calc(100% / 2);
  }

  @include media('>tablet') {
    width: calc(100% / 3);
  }

  p {
    margin-bottom: 0;
  }
}

.innerWrapper {
  border-radius: 2px;
  font-size: 16px;
  height: 100%;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  letter-spacing: unset;

  @include media('>phone') {
    margin-right: 20%;
    width: 80%;
  }
}

.date {
  color: #434343;
  height: 30px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  svg {
    height: 22px;
    margin-right: 10px;
    transform: translateY(-2px);
    padding: 2px;
  }
}

.header {
  line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  @include media('>phone') {
    line-clamp: 4;
  }
}

.source {
  //height: 40%;
  //overflow: hidden;

  //@include media('>phone') {
  //  height: 20%;
  //}
  //color: #434343;
}

.menuTrigger {
  margin: 5px 0 10px 0;
  flex-shrink: 0;
  align-self: start;
}

.divider {
  width: 100%;
  height: 1px;
  background: $gray;
  margin: 15px 0 18px;
  flex-shrink: 0;
}

.bgImg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  filter: brightness(1) saturate(0) blur(2px) opacity(0.05);
  opacity: 0.6;
}

.video {
  opacity: 0.45;
  filter: grayscale(1) blur(0px) brightness(123%);
}
