@import '/src/shared/styles';

.policyContainer {
  .leftCol {
    display: none;
  }

  @include media('>tablet') {

    .innerContent {
      width: 60%;
      float: right;
      box-sizing: border-box;
      padding: 180px 200px 0 0;
    }

    .leftCol {
      position: absolute;
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;
      //justify-content: center;
      padding-top: 200px;
      box-sizing: border-box;
      align-items: center;
      left: 0;
      top: 0;

      .logoImg {
        width: 70%;
        opacity: 0.2;
        position: absolute;
        bottom: 0;
        transform: translateY(15%);
      }

      .header {
        width: 50%;
        font-size: 90px;
        line-height: 91px;
        font-weight: 100;
      }
    }
  }
}

.policy {
  font-size: inherit;
  line-height: 0.5em;
  font-weight: inherit;
  text-decoration: none;
  padding: 0;
  border: none;
  margin: 0 0 0 10px;
  text-transform: none;
  letter-spacing: 0em;
  border-bottom: 1px solid $black;
}
