@import "/src/media-query-mixins";
@import "/src/utils";

.container {
  @include media('>phone') {
    @include orientation(landscape) {
      display: flex;
    }
  }
}

.img {
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  flex-shrink: 0;

  .inner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }

  @include media('>phone') {
    @include orientation(landscape) {
      width: 30vw;
      float: left;
    }
  }

  @include media('>tablet') {
    height: 100%;
    width: calc(50% - 15px);
    float: left;
  }
}

.txt {
  @include media('>phone') {
    //margin: 0 0 25px 0;

    @include orientation(landscape) {
      //margin: 0 0 25px;
    }
  }


  //@include media('>tablet') {
  //  font-size: 18px;
  //  line-height: 30px;
  //  margin: 0 0 25px;
  //}
  //
  //@include media('>desktop') {
  //  font-size: 22px;
  //  line-height: 37px;
  //}
}

.btn {
  text-decoration: none;
  display: inline-block;
  flex-shrink: 0;
  width: 100%;
  margin-top: 20px;

  @include media('>phone') {
    margin: 0;
  }
}

.rightCol {
  @include media('>phone') {
    @include orientation(landscape) {
      margin-left: 40PX;

      p {
        margin: 0 0 25px 0;
      }
    }
  }

  @include media('>tablet') {
    width: 50%;
    float: right;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      margin: 0 0 25px 0;
    }
  }
}

.indicator {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hide {
  opacity: 0;
}
