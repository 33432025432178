@import "/src/media-query-mixins";
@import "/src/colors";
@import "/src/shared/styles/layout";

.header {
  text-transform: uppercase;
  margin: 0;
  position: fixed;
  width: 66vw;
  top: 21%;
  height: 37px;
  z-index: 1;
  left: $gutter-phone;
  pointer-events: none;
  color: $green;

  @include media('>phone') {
    width: 58vw;
    height: 12px;
    top: 22%;
    left: $gutter-tablet;
  }

  @include media('>tablet') {
    width: 74vw;
    top: 23%;
    left: $gutter-desktop;
    font-size: 18px;
    line-height: 18px;
  }

  @include media('>desktop') {
    left: $gutter-wide-screen;
    font-size: 20px;
    line-height: 20px;
  }
}

.index {
  position: absolute;
  top: 0;
  left: 0;
}

.label {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
}

.divider {
  display: none;
  height: 1px;
  width: 68px;
  margin-right: 25px;
  position: absolute;
  top: 7px;
  left: 40px;

  @include media('>phone') {
    display: inline-block;
  }
}
