@import "/src/media-query-mixins";

.footNotes {
  margin: 0;
  padding: 17px 0 0 0;
  width: 100%;
  counter-reset: item;
  border-top: 1px solid #5E5E5E;
  font-size: 12px;
  line-height: 20px;
  font-weight: 200;

  @include media('>phone') {
    position: absolute;
    bottom: 0;
    font-size: 14px;
    line-height: 23px;
  }

  @include media('>desktop') {
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
  }

  &.hideTopLine {
    border-top: none;
  }

  li {
    display: block;

    &:before {
      content: "(" counter(item) ") ";
      counter-increment: item;
      width: 1.4em;
      display: inline-block;
    }
  }
}
