@import '/src/shared/styles';

.shelf {
  @include media('>phone') {
    height: 300px;

    :global {
      .carousel-item {
        padding-right: 4%;
      }
    }
  }

  @include media('>tablet') {
    height: 400px;

    @include orientation('landscape') {
      height: 65%;
    }
  }
}

.outerWrapper {
  width: 100%;
  position: relative;

  @include media('>phone') {
    width: calc(100% / 2);
  }

  @include media('>tablet') {
    width: calc(100% / 3);
  }
}

.innerWrapper {
  width: 100%;
  // height: 100%;
  padding: 0;
  box-sizing: border-box;

  @include media('>phone') {
    margin-right: 10%;
    width: 90%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerRight {
  display: flex;
  align-items: flex-end;
  align-items: center;

  svg {
    height: 24px;
    margin-left: 10px;
    transform: translateY(-2px);
  }

  p {
    line-height: 0;
  }
}

.name {
  font-size: 23px;
  line-height: 23px;
  font-weight: 300;
}

.img {
  border: 1px solid;
  margin-bottom: 10px;
  box-sizing: border-box;
  border-radius: 3px;
}

.p {
  font-size: 18px;
  line-height: 1.5em;
  font-weight: 300;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media('>phone') {
    font-weight: 200;
    line-height: 1.7em;
  }

  @include media('>desktop') {
    font-size: 22px;
  }
}

.vest {
  color: #ED1C24;

  .img {
    border-color: #ED1C24;
  }
}

.viola {
  color: #662D91;

  .img {
    border-color: #662D91;
  }
}

.frame {
  color: #0272BA;

  .img {
    border-color: #0272BA;
  }
}

.frameFr {
  color: #22B0AF;

  .img {
    border-color: #22B0AF;
  }
}

