@import "/src/media-query-mixins";

.content {
  color:  #707070;

  @include media('>tablet') {
    height: 65%;
    width: 35vw;
    left: 48vw;
  }
}

.list {
  box-sizing: border-box;
  position: relative;
  padding: 0;

  @include media('>phone') {
    font-size: 28px;
    line-height: 37px;
    width: 100%;
  }

  @include media('>desktop') {
    font-size: 40px;
    line-height: 45px;
  }
}

.listItem {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 25px;
  display: flex;
}

.square {
  height: 17px;
  width: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
  transform: translate(-0.5vw, 1px);

  @include media('>phone') {
    transform: translate(-0.25vw, 5px);
    margin-right: 15px;
  }

  @include media('>tablet') {
    transform: translate(0, 30%);
    margin-right: 20px;
  }

  @include media('>desktop') {
    transform: translate(0, 60%);
  }
}

.art {
  height: 25%;
  position: absolute;
  bottom: 0;
  z-index: -1;

  img {
    width: auto;
  }

  @include media('>phone') {
    height: 40%
  }

  @include media('>tablet') {
    height: 70%
  }
}
