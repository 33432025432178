@import "/src/shared/styles";

a.button {
  text-decoration: none;
  color: inherit;
}

.color {
  color: white;
}

a.button.color {
  color: white;
}

.button {
  cursor: pointer;
  transition: .2s ease-out;
  font-size: 14px;
  line-height: 1em;
  font-weight: 400;
  padding: 18px 20px 14px;
  letter-spacing: 0.08ch;

  @include media('>tablet') {
    font-size: 18px;
  }

  &.disable {
    cursor: none;
    opacity: 0.2;
    pointer-events: none;
  }
}
