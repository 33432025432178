@import "/src/media-query-mixins";
@import "/src/shared/styles/layout";

.header {
  text-transform: uppercase;
  margin: 0;
  position: fixed;
  width: calc(100vw - #{2 * $gutter-phone});
  top: 21%;
  height: 12px;
  z-index: 1;
  left: $gutter-phone;
  pointer-events: none;
  display: flex;
  align-items: center;
  line-height: 1.18em;

  @include media('>phone') {
    width: calc(100vw - #{2 * $gutter-tablet});
    left: $gutter-tablet;
    display: inline-block;
    top: 18%;

    @include orientation('landscape') {
      top: 16%;
    }
  }

  @include media('>tablet') {
    width: calc(100vw - #{2 * $gutter-desktop});
    left: $gutter-desktop;
    top: 16%;
  }

  @include media('>desktop') {
    width: calc(100vw - #{2 * $gutter-wide-screen});
    top: 17%;
    left: $gutter-wide-screen;
  }
}

.index {
  display: inline-block;
}

.label {
  display: inline-block;

  @include media('>phone') {
    top: 0;
    display: inline-block;
  }
}

.divider {
  height: 1px;
  display: inline-block;
  width: 25px;
  position: relative;
  bottom: 2px;
  flex-shrink: 0;
  margin: 0 15px;

  @include media('>phone') {
    width: 68px;
    display: inline-block;
    position: relative;
    bottom: 4px;
    margin: 0 25px;
  }
}
