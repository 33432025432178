@import "/src/shared/styles";

.reader {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 2;
  transform: translateY(100%);
  background: rgb(248, 248, 248);
  box-sizing: border-box;
  transition: 1s $ease;
  //-webkit-overflow-scrolling: auto;

  &.open {
    transform: translateY(0);
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
  }
}

.content {
  height: calc(100% - 58px);
  width: 100%;
  //overflow: hidden auto;
  padding: 20px 40px 130px;
  box-sizing: border-box;
  color: $black;
  overflow: auto;
  display: inline-flex;
  flex-direction: column;
  //align-items: center;
  //-webkit-overflow-scrolling: auto;
  font-weight: 300;
  line-height: 1.7em;
  font-size: 18px;

  h1 {
    font-size: 17px;
    text-transform: uppercase;
    line-height: 15px;
    margin: 15px 0;
    font-weight: 500;
  }

  h2 {
    font-size: 17px;
    font-weight: 400;
    margin: 15px 0 45px;
  }

  ul {
    list-style: disc;
    //list-style-position: inside;
    margin: 10px 0;
    padding-left: 19px;

    :global {
      li.ql-indent-1 {
        list-style: circle;
        //list-style-position: inside;
        margin-left: 23px;
      }
    }

  }
}

.closeBtn {
  color: white;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 10px 10px !important;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
  position: relative
}
