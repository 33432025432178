@import '/src/shared/styles';

.mediaLoader {
  width: 100%;
  height: 100%;
}

.activity {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
}

.src {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;
  @extend %fadeIn;
  animation-duration: 0.75s;
}

.cover {
  object-fit: cover;
}
