.trigger {
  text-transform: uppercase;
  padding-right: 40px;
  border-radius: 30px;
  border: 1px solid;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  font-size: 15px;

  .arrow {
    height: 33px;
    margin-left: 10px;
    margin-right: -8px;
    transition: .2s ease-out;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -1px;

    &.flip {
      transform: scaleY(-1) translateY(14px);
    }
  }
}

.menu {
  font-family: 'calibre', sans-serif;
  line-height: 1em;
  text-transform: uppercase;
  text-align: left;

  li {
    padding: 10px 15px;
    cursor: pointer;
  }
}

:global {
  .tippy-tooltip {
    padding: 10px 0 7px;
  }
}
